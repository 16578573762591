var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "keep-alive",
        [
          _vm.$route.meta.keepneed && _vm.isRouterAlive
            ? _c("router-view", {
                on: { suspends: _vm.getChild, changeCatgory: _vm.getPrice }
              })
            : _vm._e()
        ],
        1
      ),
      (_vm.isRouterAlive
      ? !_vm.$route.meta.keepneed
      : false)
        ? _c("router-view", {
            on: { suspends: _vm.getChild, changeCatgory: _vm.getPrice }
          })
        : _vm._e(),
      !_vm.$route.meta.sevrice && _vm.processEnv ? _c("sevrice") : _vm._e(),
      !_vm.$route.meta.suspends && _vm.suspends
        ? _c("suspends", { attrs: { initPriceName: _vm.initPriceName } })
        : _vm._e(),
      _c("footerbox", { staticClass: "footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }